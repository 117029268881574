<template>
  <section class="wx-panel mt-6 mt-lg-8 mt-xl-10 bgcolor-element-layer1" id="DevicesWifiSignalStrength">
    <header class="mb-4">
      <h2>
        <v-icon class="mt-n1">mdi-wifi</v-icon>
        {{ $t("devices.wifiSignal.title") }}
        <span class="ml-2 wx-typo-xs color-text-subtle-theme">({{ $t("devices.wifiSignal.signalUnitInTitle") }})</span>
      </h2>
    </header>

    <form ref="form">
      <!-- - - - - - - - - -     Start & End dates     - - - - - - - - - - - - -->
      <wx-date-range-picker
        v-if="this.activeFactory"
        :default-period-index="1"
        :timezone="getDetectedTimeZone()"
        :week-first-day="activeFactoryWeekFirstDay"
        :max-past-months="maxPastMonths"
        :error-messages="dateRangeErrorMessages"
        class="mt-1"
        @newDateRange="setDateRange"
      />
    </form>
    <wx-chart-line-graph
      :chart-data="wifiSignalStrengthGraphData"
      :chart-options="wifiSignalStrengthGraphOptions"
      css-classes="canvas-container mt-4"
    />
  </section>
</template>

<script>
import WxDateRangePicker from "@/components/ui/WxDateRangePicker.vue";
import WxChartLineGraph from "@/components/ui/WxChartLineGraph.vue";
import { mapActions, mapGetters } from "vuex";
import DeviceService from "@/components/devices/DeviceService";
import * as TimeUtils from "@/store/TimeUtils";
import { DateTime } from "luxon";
import StyleValues from "@/styles/_values.scss";
import moment from "moment-timezone";
import ErrorHandling from "@/components/ErrorHandling";

export const wifiSignalStrengthIdealTarget = -50.0;
export const wifiSignalStrengthMinTarget = -60.0;

// noinspection JSUnusedGlobalSymbols
export default {
  name: "DevicesWifiSignalStrength",
  components: {
    WxChartLineGraph,
    WxDateRangePicker,
  },
  data() {
    return {
      dateRange: null,
      dateRangeErrorMessages: [],
      wifiSignalStrengthGraphData: {},
      xAxisTicksRotation: 0,
    };
  },
  computed: {
    ...mapGetters("navigation", ["activeFactory", "activeFactoryProductionUnits"]),
    ...mapGetters("user", ["theme", "isWorximityAdmin", "worximityAdminAllowedCoverageInMonths"]),
    maxPastMonths() {
      if (this.isWorximityAdmin) {
        return this.worximityAdminAllowedCoverageInMonths;
      }
      return 1;
    },
    activeFactoryWeekFirstDay() {
      if (this.activeFactory && this.activeFactory.weekFirstDay) {
        return this.activeFactory.weekFirstDay;
      } else {
        return "monday";
      }
    },
    wifiSignalStrengthGraphOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 0,
            right: 20,
            top: 10,
            bottom: 10,
          },
        },
        animation: {
          duration: 0,
        },
        scales: {
          y: {
            display: true,
            min: -80,
            max: -20,
            ticks: {
              max: -20,
              min: -80,
            },
            grid: {
              display: true,
            },
            title: {
              display: true,
              align: "center",
            },
          },
          x: {
            display: true,
            grid: {
              display: true,
            },
            title: {
              display: true,
              align: "center",
            },
            ticks: {
              minRotation: this.xAxisTicksRotation,
              maxRotation: this.xAxisTicksRotation,
            },
          },
        },
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              generateLabels: (chart) => {
                return chart.data.datasets.map((dataset, i) => ({
                  text: dataset.label,
                  fillStyle: dataset.borderColor,
                  color: dataset.borderColor,
                  stokeStyle: dataset.borderColor,
                  fontColor: dataset.borderColor,
                  hidden: !chart.isDatasetVisible(i),
                  datasetIndex: i,
                }));
              },
            },
            onClick: (e, legendItem, legend) => {
              const index = legendItem.datasetIndex;
              const ci = legend.chart;

              const isOnlyVisible = ci.data.datasets.every(
                (dataset, i) => i === index || dataset.hidden
              );

              if (isOnlyVisible) {
                ci.data.datasets.forEach((dataset) => {
                  dataset.hidden = false;
                });
              } else {
                ci.data.datasets.forEach((dataset, i) => {
                  dataset.hidden = i !== index;
                });
              }

              ci.update();
            },
          },
          annotation: {
            annotations: {
              idealTarget: {
                type: "line",
                yMin: wifiSignalStrengthIdealTarget,
                yMax: wifiSignalStrengthIdealTarget,
                borderColor: this.getTargetLineColor(),
                borderWidth: 1,
                borderDash: [3, 3],
                label: {
                  content: this.$t("devices.wifiSignal.idealTarget", { target: wifiSignalStrengthIdealTarget }),
                  enabled: true,
                  position: "end",
                  color: this.getTargetLineColor(),
                  backgroundColor: "transparent",
                  yAdjust: 15,
                  textAlign: "center",
                },
              },
              strictMinimum: {
                type: "line",
                yMin: wifiSignalStrengthMinTarget,
                yMax: wifiSignalStrengthMinTarget,
                borderColor: this.getTargetLineColor(),
                borderWidth: 1,
                borderDash: [3, 3],
                label: {
                  content: this.$t("devices.wifiSignal.strictMinimumTarget", { target: wifiSignalStrengthMinTarget }),
                  enabled: true,
                  position: "end",
                  color: this.getTargetLineColor(),
                  backgroundColor: "transparent",
                  yAdjust: 15,
                  textAlign: "center",
                },
              },
            },
          },
          tooltip: {
            enabled: true,
            backgroundColor: this.getTooltipBackgroundColor(),
            titleFont: { size: 14, weight: "normal" },
            bodyFont: { size: 14, weight: "bold" },
            footerFont: "{weight: 'normal'}",
            displayColors: false,
            callbacks: {
              title: function title(tooltipItems) {
                let tooltipItem = tooltipItems != null && tooltipItems instanceof Array ? tooltipItems[0] : null;
                let time = null;
                let deviceId = "";
                let dataset = tooltipItem.dataset;
                if (dataset) {
                  deviceId = dataset.label;
                  const point = dataset.data[tooltipItem.dataIndex];
                  if (point) {
                    time = point.date;
                  }
                }
                return `${deviceId} - ${time}`;
              },
              label: function graphPointLabel(tooltipItem) {
                if (tooltipItem) {
                  let dataset = tooltipItem.dataset;
                  if (dataset) {
                    const point = dataset.data[tooltipItem.dataIndex];
                    if (point) {
                      return `${point.y.toFixed(1)} dBm`;
                    }
                  }
                  return tooltipItem.parsed.y.toFixed(1) + " dBm";
                }
                return "";
              },
              labelTextColor: function graphPointColor(tooltipItem) {
                if (tooltipItem) {
                  let dataset = tooltipItem.dataset;
                  if (dataset) {
                    const point = dataset.data[tooltipItem.dataIndex];
                    if (point) {
                      if (point.y >= wifiSignalStrengthIdealTarget) {
                        return "#12b2ac";
                      } else if (point.y < wifiSignalStrengthMinTarget) {
                        return "#ff3952";
                      } else {
                        return "#fcc01c";
                      }
                    }
                  }
                }
                return "#12b2ac"; // default: good - green
              },
            },
          },
        },
      };
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationError"]),
    getDetectedTimeZone() {
      if (this.activeFactory && this.activeFactory.timezone) {
        return this.activeFactory.timezone;
      } else {
        const zone = moment.tz.guess(true);
        return zone === null || zone === undefined ? "America/Montreal" : zone;
      }
    },
    resetDateRangeValidations() {
      this.dateRangeErrorMessages = [];
    },
    validateDateRange() {
      this.resetDateRangeValidations();
      if (this.dateRange) {
        if (this.dateRange.startDate === null || this.dateRange.startDate === undefined) {
          this.dateRangeErrorMessages = [this.$t("devices.wifiSignal.errors.noStartDate")];
          return false;
        }
        if (this.dateRange.endDate === null || this.dateRange.endDate === undefined) {
          this.dateRangeErrorMessages = [this.$t("devices.wifiSignal.errors.noEndDate")];
          return false;
        }
      }
      return true;
    },
    setDateRange(dateRange) {
      if (!this.validateDateRange()) return;
      this.dateRange = { ...dateRange };
      this.getWiFiSignalStrengthGraphData();
    },
    getWiFiSignalStrengthGraphData() {
      const startDate = DateTime
        .fromISO(this.dateRange.startDate, { zone: this.activeFactory?.timezone })
        .toFormat(TimeUtils.DATE_FORMAT);
      const endDate = DateTime
        .fromISO(this.dateRange.endDate, { zone: this.activeFactory?.timezone })
        .plus({ days: 1 })
        .toFormat(TimeUtils.DATE_FORMAT);

      DeviceService.getWiFiSignalStrength(this.activeFactory.id, startDate, endDate)
        .then((httpResponse) => {
          let granularity = httpResponse.data.granularity;

          if (granularity === "BY_HOUR") {
            this.xAxisTicksRotation = 0;
          }
          if (granularity === "BY_DAY") {
            this.xAxisTicksRotation = 90;
          }
          let datasets = [];
          let signalStrengthPerDevice = httpResponse.data.signal_strength_per_device;
          if (signalStrengthPerDevice && signalStrengthPerDevice.length > 0) {
            datasets = signalStrengthPerDevice.map((signalStrengthForDevice, index) =>
              this.toGraphDataset(signalStrengthForDevice, granularity, index),
            );
          }
          let labels = [];
          if (datasets.length > 0) {
            labels = datasets[0].data.map((p) => p.date);
          }
          this.wifiSignalStrengthGraphData = {
            labels: labels,
            datasets: datasets,
          };
        })
        .catch((error) => {
          this.showOperationError(ErrorHandling.buildErrorsMessages(error.response, this.getErrorMessage));
          this.dateRange = null;
        });
    },
    getErrorMessage(code) {
      return this.$t("common.errors.default", { code: code });
    },
    toGraphDataset(signalStrengthForDevice, granularity, index) {
      const timeFormat = granularity === "BY_HOUR" ? TimeUtils.TIME_FORMAT_HH_MM : TimeUtils.DATE_FORMAT;
      let points = signalStrengthForDevice.signal_strength_over_time.map((strengthForTime) => {

        const dateTime = DateTime.fromMillis(strengthForTime.timestamp_utc)
          .setZone(this.activeFactory.timezone)

        const isMidnight = dateTime.hour === 0 && dateTime.minute === 0 && dateTime.second === 0;
        let conditionalTimeFormat = null;
        if (timeFormat === TimeUtils.TIME_FORMAT_HH_MM && isMidnight) {
          conditionalTimeFormat = TimeUtils.DATE_TIME_FORMAT_NO_SECONDS;
        } else {
          conditionalTimeFormat = timeFormat;
        }
          
        const date = dateTime.toFormat(conditionalTimeFormat);
        
        return {
          x: strengthForTime.timestamp_utc,
          y: strengthForTime.level,
          date: date,
        };
      });
      return {
        label: signalStrengthForDevice.device_id,
        order: index,
        pointRadius: 0,
        pointHitRadius: 10,
        pointHoverBackgroundColor: "rgb(255, 0, 0)",
        borderColor: this.getLineColor(index),
        borderWidth: 2,
        backgroundColor: "transparent",
        tension: 0,
        data: points,
      };
    },
    getLineColor(index) {
      if (this.theme === "light") {
        return StyleValues[`wxData_lineColor${(index % StyleValues.wxData_colorsCount) + 1}Light`];
      } else {
        return StyleValues[`wxData_lineColor${(index % StyleValues.wxData_colorsCount) + 1}Dark`];
      }
    },
    getTooltipBackgroundColor() {
      return this.theme === "light" ? StyleValues.wxData_bgColorLight : StyleValues.wxData_bgColorDark;
    },
    getTargetLineColor() {
      return this.theme === "light" ? StyleValues.color_text_themeLight : StyleValues.color_text_themeDark;
    },
  },
};
</script>

<style scoped lang="scss"></style>
